<template>
  <div>
    <div class="d-flex mb-6">
      <router-link to="/jobs" class="text-decoration-none">
        <v-btn class="primary-btn"> <v-icon>mdi-arrow-left</v-icon> Back</v-btn>
      </router-link>
    </div>
    <pop-up-layout :title="'Edit Job'">
      <div class="row">
        <div class="col-md-6">
          <v-text-field
            label="Job No."
            placeholder="Job No."
            v-model="form.job_no"
            v-bind="basic"
            disabled
            outlined
          ></v-text-field>
        </div>
        <div class="col-md-6">
          <v-text-field
            label="Job Title"
            placeholder="Job Title*"
            v-model="form.job_title"
            v-bind="basic"
            outlined
          ></v-text-field>
        </div>
        <div class="col-md-6">
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="form.start_date"
                clearable
                label="Start Date"
                readonly
                v-bind="{ ...basic, ...attrs }"
                v-on="on"
                @click:clear="form.start_date = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.start_date"
              @change="menu1 = false"
            ></v-date-picker>
          </v-menu>
        </div>
        <div class="col-md-6">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="form.due_date"
                clearable
                label="End Date"
                readonly
                v-bind="{ ...basic, ...attrs }"
                v-on="on"
                @click:clear="form.due_date = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.due_date"
              @change="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </div>
        <div class="col-md-6">
          <v-text-field
            label="Progress"
            placeholder="Progress"
            v-model="form.progress"
            v-bind="basic"
            outlined
          ></v-text-field>
        </div>
        <div class="col-md-6">
          <v-select
            :items="jobTypeList"
            item-text="title"
            item-value="id"
            v-bind="basic"
            label="Job Type"
            v-model="form.job_type_id"
            placeholder="Please Select"
            outlined
          ></v-select>
        </div>
        <div class="col-md-6">
          <v-select
            :items="jobStatusList"
            item-text="title"
            item-value="id"
            v-bind="basic"
            label="Job Status"
            v-model="form.job_status_id"
            placeholder="Please Select Status"
            outlined
          ></v-select>
        </div>
        <div class="col-md-6">
          <v-select
            :items="customerList"
            item-text="full_name"
            item-value="id"
            v-bind="basic"
            v-model="form.customer_id"
            label="Customer"
            placeholder="Please Select"
            outlined
          ></v-select>
        </div>
        <div class="col-md-6">
          <v-text-field
            label="Job Site"
            placeholder="Job Site"
            v-model="form.job_site"
            v-bind="basic"
            outlined
          ></v-text-field>
        </div>
        <div class="col-md-6">
          <v-text-field
            label="Code"
            placeholder="Code"
            v-model="form.code"
            v-bind="basic"
            outlined
          ></v-text-field>
        </div>
        <div class="col-md-6">
          <v-select
            :items="jobPriorityList"
            item-text="title"
            item-value="id"
            v-bind="basic"
            label="Job Priority"
            v-model="form.priority_id"
            placeholder="Please Select Priority"
            outlined
          ></v-select>
        </div>
        <div class="col-md-6">
          <v-textarea
            outlined
            name="input-7-4"
            v-bind="basic"
            v-model="form.job_description"
            label="Job Description"
            value=""
          ></v-textarea>
        </div>
        <div class="col-md-6">
          <p class="font-weight-bold">Is Internal?</p>
          <!-- <v-radio-group v-model="row" row> -->
            <input
              type="radio"
              label="Yes"
              v-model="form.is_internal"
              value="1"
            /> Yes <br>
            <input
              type="radio"
              label="No"
              v-model="form.is_internal"
              value="0"
            /> No
          <!-- </v-radio-group> -->
        </div>
        <div class="col-md-12">
          <hr style="border-top: 1px solid #ddd">
        </div>
        <div class="col-md-12">
          <v-btn
            type="button"
            v-on:click="update"
            :loading="loading"
            class="submit-btn mr-3"
          >
            Update
          </v-btn>
          <router-link to="/jobs">
            <v-btn class="cancel-btn float-right">Cancel</v-btn>
          </router-link>
        </div>
      </div>
    </pop-up-layout>
  </div>
</template>

<script>
import util from "@/util/util";
import PopUpLayout from "@/components/PopUpLayout.vue";
export default {
  components: {
    PopUpLayout,
  },
  data() {
    return {
      util,
      basic: util.input.basic,
      id: this.$route.params.id,
      items: ["Foo", "Bar", "Fizz", "Buzz"],
      menu1: false,
      menu2: false,
      start_date: "",
      jobTypeList: [],
      jobStatusList: [],
      jobPriorityList: [],
      customerList: [],
      due_date: "",
      loading: false,
      form: {
        job_no: "",
        job_title: "",
        start_date: "",
        due_date: "",
        progress: "",
        job_type_id: null,
        job_status_id: null,
        customer_id: null,
        job_site: "",
        code: "",
        priority_id: null,
        job_description: "",
        is_internal: 1,
      },
      row: null,
    };
  },
  mounted() {
    this.getJobData();
    this.getJobTypeList();
    this.getJobStatusList();
    this.getJobPriorityList();
    this.getCustomerList();
    console.log(this.id);
  },
  methods: {
    getJobData() {
      util
        .http({ url: "/job/" + this.$route.params.id, method: "get" })
        .then((res) => {
          console.log(res);
          this.form.job_no = res.data.data.job_no;
          this.form.job_title = res.data.data.job_title;
          this.form.start_date = res.data.data.start_date;
          this.form.due_date = res.data.data.due_date;
          this.form.progress = res.data.data.progress;
          this.form.job_type_id = res.data.data.job_type_id;
          this.form.job_status_id = res.data.data.job_status_id;
          this.form.customer_id = res.data.data.customer_id;
          this.form.job_site = res.data.data.job_site;
          this.form.code = res.data.data.code;
          this.form.priority_id = res.data.data.priority_id;
          this.form.job_description = res.data.data.job_description;
          this.form.is_internal = String(res.data.data.is_internal);
        });
    },
    update() {
      this.loading = true;
      util
        .http({
          url: "/job/" + this.$route.params.id,
          method: "PATCH",
          data: { ...this.form },
        })
        .then((resp) => {
          this.loading = false;
          console.log(resp);
          if (resp.data.status == 1) {
            util.notify(1, "Job Updated Successfully");
            this.$router.push({ name: "jobs.detail", params: { id: resp.data.data.id } });
          } else {
            util.notify(0, resp.data.errors[0]);
          }
        });
    },
    getJobTypeList() {
      util.http({ url: "/job-type/get-all" }).then((res) => {
        this.jobTypeList = res.data.data;
      });
    },
    getJobStatusList() {
      util.http({ url: "/job-status/get-all" }).then((res) => {
        this.jobStatusList = res.data.data;
      });
    },
    getJobPriorityList() {
      util.http({ url: "/job-priority/get-all" }).then((res) => {
        this.jobPriorityList = res.data.data;
      });
    },
    getCustomerList() {
      util.http({ url: "/customer/get-all" }).then((res) => {
        this.customerList = res.data.data;
      });
    },
  },
};
</script>
